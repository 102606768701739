import FIRST_SOUND from 'assets/audios/sound1.mp3';
import SECOND_SOUND from 'assets/audios/sound2.mp3';
import THIRD_SOUND from 'assets/audios/sound3.mp3';
import FOURTH_SOUND from 'assets/audios/sound4.mp3';
import FIFTH_SOUND from 'assets/audios/sound5.mp3';

export const SOUNDS_PATHS = {
  1: FIRST_SOUND,
  2: SECOND_SOUND,
  3: THIRD_SOUND,
  4: FOURTH_SOUND,
  5: FIFTH_SOUND,
};
