import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {

  },
  restaurantName: {
    marginTop: '8px',
    marginBottom: '4px',
    fontSize: '1rem',
  },
});
