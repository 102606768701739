const ROUTES = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  CONFIRM_PASSWORD: '/confirm-password/:token?',
  RESTAURANTS: '/restaurants',
  RESTAURANTS_DASHBOARD: '/restaurant-dashboard',
  ORDERS: '/orders',
  ORDERS_CONFIRMED: '/orders/confirmed',
  ORDERS_SCHEDULED: '/orders/scheduled',
  ORDERS_HISTORY: '/orders/history',
  ORDERS_SEARCH: '/orders/search',
  USERS_LIST: '/users-list',
  USER: '/user',
  MODIFIERS: '/menu/modifiers',
  EDIT_PROFILE: '/edit-profile',
  CHANGE_PASSWORD: '/change-password',
  HOURS: '/hours',
  EDIT_TERMS_AND_CONDITIONS: '/edit-terms-and-conditions',
  ACCESS_DENIED: '/access-denied',
  NOT_FOUND: '/not-found',
  MENU_ITEMS: '/menu',
  ITEM: '/item',
  MENU_SCHEDULE: '/menu/schedules',
  MENU_LABELS: '/menu/labels',
  MENU: '/menu',
  BOUNDARIES: '/boundaries',
  PROMOTIONS: '/promotions',
  SETTINGS: '/settings',
  REVIEWS: '/reviews',
  REPORTS: '/reports',
  STOP_TAKING_ORDERS: '/reports/stop-taking-orders',
  MESSAGES: '/messages',
  MESSAGES_ROOM: '/messages/:id',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
};

export default ROUTES;
