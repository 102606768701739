export const AUTH_PATH = '/security/desktop/email';
export const RESTAURANTS_PATH = '/restaurants';
export const USERS_PATH = '/users';
export const PROFILE_PATH = '/profile';
export const HOURS_PATH = '/hours';
export const MODIFIERS_PATH = '/menu-modifiers';
export const MENU_ITEMS_PATH = '/menu-items';
export const MENU_SECTIONS_PATH = '/menu-sections';
export const MENU_SCHEDULE_PATH = '/menu-schedules';
export const MENU_LABELS_PATH = '/menu-labels';
export const ORDERS_PATH = '/orders';
export const BOUNDARIES_PATH = '/delivery-boundaries';
export const ZONES_PATH = '/delivery-zones';
export const PROMOTION_PATH = '/promotions';
export const REVIEWS_PATH = '/reviews';
export const REPORTS_PATH = '/reports';
export const TERMS_AND_CONDOTIONS = '/terms-and-conditions';
